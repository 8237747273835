@import url(https://fonts.googleapis.com/css2?family=Commissioner:wght@200;300;700;900&family=Manrope:wght@300;500;600;700&display=swap);
body {
  margin: 0;
  font-family: 'Manrope',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "ThickerBold";
  src: local("ThickerBold"),
    url(/static/media/Thicker_Extrabold.126673b7.otf) format("truetype");
  font-weight: bold;
}
* {
  font-family: 'Manrope', sans-serif !important;
}

html,
body {
  font-size: 61.5%;
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  max-height: 100vh;
  overflow: hidden;
}

@media (max-height: 900px) {
  html,
  body {
    font-size: 50%;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  display: flex !important;
  justify-content: center !important;
}

.modal-content {
  border-radius: 3.2rem !important;
  min-width: 60vw;
  max-width: 75vw !important;
}

.rate .modal-content {
  border-radius: 3.2rem !important;
  width: 85vw !important;
}

.keyboard .modal-dialog {
  position: fixed;
  bottom: 0px;
  width: 100% !important;
  max-width: 100% !important;
  margin-bottom: 0 !important;
}

.keyboard .modal-content {
  width: 100% !important;
  border: 0px !important;
  max-width: 100vw !important; 
  border-top-left-radius: 0.8rem !important;
  border-top-right-radius: 0.8rem !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  box-shadow: 0px -4px 32px 0px rgba(0, 0, 0, 0.25);
}

.language .modal-dialog {
  position: fixed;
  top: 10vh;
  right: 5vw;
}

.language .modal-content {
  width: 100% !important;
  min-width: 100% !important;
  border: 0px !important;
  border-radius: 1.6rem !important;
  padding: 0 10rem 0 4rem;
}

.form-control {
  background-color: #fafafa;
  height: 7vh;
  border: 2px solid #e6e6e6;
  border-radius: 1.2rem;
  font-size: 3.5rem;
  padding: 0 30px;
}

.confirm_input.form-control {
  background-color: transparent;
  border: none;
  width: 26vw;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  border-radius: 1.2rem;
  font-size: 2rem;
  line-height: 0;
  font-weight: 600;
  padding: 0 5px 0;
}

.form-control:focus {
  background-color: #fafafa;
  border: 2px solid #e6e6e6;
  box-shadow: none;
}

.confirm_input.form-control:focus {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.form-control::-webkit-input-placeholder {
  font-size: 2.4rem;
}

.form-control::placeholder {
  font-size: 2.4rem;
}

.hg-theme-default {
  width: 100% !important;
}

.simple-keyboard {
  width: 100% !important;
}

.hg-theme-default .hg-button {
  height: 50px !important;
}

.fs80 {
  font-size: 8rem;
}

.fs72 {
  font-size: 7.2rem;
}

.fs56 {
  font-size: 5.6rem;
}
.fs48 {
  font-size: 4.8rem;
}

.fs45 {
  font-size: 4.5rem;
}

.fs40 {
  font-size: 4rem;
}

.fs38 {
  font-size: 3.8rem;
}

.fs36 {
  font-size: 3.6rem;
}

.fs34 {
  font-size: 3.4rem;
}

.fs32 {
  font-size: 3.4rem;
}

.fs30 {
  font-size: 3rem;
}

.fs28 {
  font-size: 2.8rem;
}

.fs26 {
  font-size: 2.6rem;
}

.fs24 {
  font-size: 2.4rem;
}

.fs22 {
  font-size: 2.2rem;
}

.fs20 {
  font-size: 2rem;
}

.fs18 {
  font-size: 1.8rem;
}

.fs16 {
  font-size: 1.6rem;
}

.fs15 {
  font-size: 1.5rem;
}

.fs14 {
  font-size: 1.4rem;
}

.fw800 {
  font-weight: 800;
}

.fw700 {
  font-weight: 700;
}

.fw600 {
  font-weight: 600;
}

.fw500 {
  font-weight: 500;
}

.fw400 {
  font-weight: 400;
}

.fw300 {
  font-weight: 300;
}

.lh110 {
  line-height: 11rem;
}

.lh72 {
  line-height: 7.2rem;
}

.lh60 {
  line-height: 6rem;
}

.lh48 {
  line-height: 4.8rem;
}

.lh45 {
  line-height: 4.5rem;
}

.lh40 {
  line-height: 4rem;
}

.lh36 {
  line-height: 3.6rem;
}

.lh32 {
  line-height: 3.2rem;
}

.lh28 {
  line-height: 2.8rem;
}

.lh26 {
  line-height: 2.6rem;
}

.lh24 {
  line-height: 2.4rem;
}

.fontGray {
  color: #808080;
}

.fontThicker {
  font-family: 'ThickerBold' !important;
}

.button__bar {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  background: transparent;
  border-radius: 40px;
  width: 300px;
  text-align: center;
}

.button__bar li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 12vh 10px 0;
  padding: 0;
}

.button__bar li button {
  border: none;
  background: rgba(255, 255, 255, 0.25);
  color: transparent;
  cursor: pointer;
  display: block;
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.button__bar li.slick-active button {
  background-color: rgba(255, 255, 255, 0.5);
}

.button__bar li button:hover,
.button__bar li button:focus {
  background: rgba(255, 255, 255, 0.5);
  outline: 0;
}

.slick-slide img {
  display: block;
  margin: auto;
}

.slick-prev:before {
  position: absolute !important;
  bottom: -45vh !important;
  left: -2vw !important;
  width: 7vw !important;
  background: transparent !important;
  height: 9vh !important;
  content: '' !important;
}

.slick-next:before {
  position: absolute !important;
  bottom: -47vh !important;
  right: -2vw !important;
  width: 26vw !important;
  background: transparent !important;
  height: 13vh !important;
  content: '' !important;
}

.hg-theme-default .hg-button span {
  font-size: 2rem;
}

@-webkit-keyframes rotate {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

ol li {
  counter-increment: muffins;
}
ol li:before {
  content: counter(muffins) '';
  color: white;
  background: #1a1a1a;
  padding: 8px 20px 10px;
  margin-right: 25px;
  border-radius: 30px;
}
ol {
  list-style: none;
  counter-reset: muffins;
}

.empty-line {
  width: 100%;
  height: 5px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  border-radius: 20px;
}

.completed-line {
  width: 100%;
  height: 5px;
  position: relative;
  overflow: hidden;
  background-color: #1a1a1a;
  border-radius: 20px;
}

.loader-line-evaluating {
  width: 100%;
  height: 5px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  border-radius: 20px;
}

.loader-line-evaluating:before {
  content: '';
  position: absolute;
  left: -1%;
  height: 5px;
  width: 98%;
  background-color: #1a1a1a;
  -webkit-animation: lineAnim 18s linear;
          animation: lineAnim 18s linear;
  border-radius: 20px;
}

.loader-line-identifying {
  width: 100%;
  height: 5px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  border-radius: 20px;
}

.loader-line-identifying:before {
  content: '';
  position: absolute;
  left: -1%;
  height: 5px;
  width: 100%;
  background-color: #1a1a1a;
  -webkit-animation: lineAnim 120s ease-out;
          animation: lineAnim 120s ease-out;
  border-radius: 20px;
}

.loader-line-grading:before {
  -webkit-animation: lineAnim 30s ease-out;
          animation: lineAnim 30s ease-out;
}

.w-90 > .modal-dialog > .modal-content {
  width: 100% !important;
  max-width: 1200px !important;
}

.loader-line-grading {
  width: 100%;
  height: 5px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  border-radius: 20px;
}

.loader-line-grading:before {
  content: '';
  position: absolute;
  left: -1%;
  height: 5px;
  width: 98%;
  background-color: #1a1a1a;
  -webkit-animation: lineAnim 30s linear;
          animation: lineAnim 30s linear;
  border-radius: 20px;
}

@-webkit-keyframes lineAnim {
  0% {
    left: -98%;
  }

  /* 50% {
    left: 0%;
    width: 100%;
  } */

  100% {
    /* left: 100%; */
    width: 98%;
  }
}

@keyframes lineAnim {
  0% {
    left: -98%;
  }

  /* 50% {
    left: 0%;
    width: 100%;
  } */

  100% {
    /* left: 100%; */
    width: 98%;
  }
}

/* Sets the containers height and width */
.base-timer {
  position: relative;
  height: 150px;
  width: 150px;
}

/* Removes SVG styling that would hide the time label */
.base-timer__circle {
  fill: none;
  stroke: none;
}

/* The SVG path that displays the timer's progress */
.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: grey;
}

.base-timer__label {
  position: absolute;

  /* Size should match the parent container */
  width: 150px;
  height: 150px;

  /* Keep the label aligned to the top */
  top: 0;

  /* Create a flexible box that centers content vertically and horizontally */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Sort of an arbitrary number; adjust to your liking */
  font-size: 48px;
}

.base-timer__path-remaining {
  /* Just as thick as the original ring */
  stroke-width: 7px;

  /* Rounds the line endings to create a seamless circle */
  stroke-linecap: round;

  /* Makes sure the animation starts at the top of the circle */
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-transform-origin: center;
          transform-origin: center;

  /* One second aligns with the speed of the countdown timer */
  transition: 1s linear all;

  /* Allows the ring to change color when the color value updates */
  stroke: currentColor;
}

.base-timer__svg {
  /* Flips the svg and makes the animation to move left-to-right */
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.base-timer__path-remaining.green {
  color: #04e700;
}

.base-timer__path-remaining.orange {
  color: rgb(255, 196, 0);
}

.base-timer__path-remaining.red {
  color: rgb(255, 77, 53);
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  font-size: 10px;
  position: relative;
  border-top: 1.1em solid rgba(26, 26, 26, 0.2);
  border-right: 1.1em solid rgba(26, 26, 26, 0.2);
  border-bottom: 1.1em solid rgba(26, 26, 26, 0.2);
  border-left: 1.1em solid #1a1a1a;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

.startButton.loader {
  margin: 60px 20px 60px -50px;
  font-size: 6px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid #93989e;
  border-right: 1.1em solid #93989e;
  border-bottom: 1.1em solid #93989e;
  border-left: 1.1em solid white;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

